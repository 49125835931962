import React, { useState, useEffect, useRef } from 'react';

export default function AddressGeneratorEffect({
  text,
  delay = 50,
  isColor,
  isMultiColor,
  isFontStyle,
  onComplete,
  isSeen,
}) {
  const [displayedCharacters, setDisplayedCharacters] = useState([]);
  const hasRunOnce = useRef(false);

  useEffect(() => {
    // Only run the word effect if isSeen is false and the effect hasn't run
    if (!isSeen && !hasRunOnce.current) {
      let currentIndex = 0;

      const getBalancedLightColor = () => {
        // Chọn ngẫu nhiên giữa các tông màu lạnh (lime, yellow)
        const colorOptions = [
          [140, 100, 20], // Vàng đất sậm
          [90, 130, 30], // Xanh chanh đậm
          [120, 100, 30], // Vàng đồng cổ
          [80, 150, 50], // Xanh rêu chanh
          [110, 90, 20], // Vàng oliu đậm
        ];
        // Chọn ngẫu nhiên một tông màu trong danh sách
        const [r, g, b] = colorOptions[Math.floor(Math.random() * colorOptions.length)];

        // Điều chỉnh một chút độ sáng để tránh bị quá tối hoặc quá sáng
        const rAdj = Math.min(255, r + Math.random() * 30);
        const gAdj = Math.min(255, g + Math.random() * 30);
        const bAdj = Math.min(255, b + Math.random() * 30);

        return `rgb(${Math.floor(rAdj)}, ${Math.floor(gAdj)}, ${Math.floor(bAdj)})`;
      };

      const interval = setInterval(() => {
        if (currentIndex < text.length) {
          const nextChar = text[currentIndex];
          setDisplayedCharacters((prev) => [...prev, { char: nextChar, color: getBalancedLightColor() }]);
          currentIndex++;
        } else {
          clearInterval(interval);
          hasRunOnce.current = true; // Mark as completed
          if (onComplete) {
            onComplete();
          }
        }
      }, 15);

      return () => clearInterval(interval);
    } else {
      setDisplayedCharacters(
        text.split('').map((t) => {
          const getBalancedLightColor = () => {
            // / lime and stuff
            const colorOptions = [
              [140, 100, 20], // Vàng đất sậm
              [90, 130, 30], // Xanh chanh đậm
              [120, 100, 30], // Vàng đồng cổ
              [80, 150, 50], // Xanh rêu chanh
              [110, 90, 20], // Vàng oliu đậm
            ];

            // Chọn ngẫu nhiên một tông màu trong danh sách
            const [r, g, b] = colorOptions[Math.floor(Math.random() * colorOptions.length)];

            // Điều chỉnh một chút độ sáng để tránh bị quá tối hoặc quá sáng
            const rAdj = Math.min(255, r + Math.random() * 30);
            const gAdj = Math.min(255, g + Math.random() * 30);
            const bAdj = Math.min(255, b + Math.random() * 30);

            return `rgb(${Math.floor(rAdj)}, ${Math.floor(gAdj)}, ${Math.floor(bAdj)})`;
          };
          return { char: t, color: getBalancedLightColor() };
        })
      );
    }
  }, [isSeen, text, isColor]);
  return (
    <div className="tracking-wide ">
      {displayedCharacters.map((item, index) => (
        <span
          key={index}
          style={{
            color: isMultiColor ? item.color : '',
            fontWeight: item.color === 'black' ? '' : isFontStyle ? 510 : '450',
            fontFamily: isFontStyle ? 'fantasy' : '',
          }}
        >
          {item.char}
        </span>
      ))}
    </div>
  );
}
