export const bookingPhrases = [
  `Để khởi tạo lô hàng từ miracle đến moon gồm starlight kiện hàng và cosmo mặt hàng, chúng ta cần chuẩn bị các thông tin sau.`,
  `Chuyến hàng từ miracle đến moon với starlight kiện và cosmo mặt hàng đang được xử lý. Dưới đây là các bước cần thực hiện.`,
  `Lô hàng xuất phát từ miracle đến moon có tổng starlight kiện hàng và cosmo mặt hàng. Hãy cùng kiểm tra thông tin cần thiết.`,
  `Bạn đang chuẩn bị gửi starlight kiện hàng từ miracle đến moon. Hãy đảm bảo có đủ thông tin sau để thuận tiện cho quá trình vận chuyển.`,
  `Để đảm bảo chuyến hàng từ miracle đến moon diễn ra suôn sẻ, chúng ta cần xác nhận starlight kiện hàng và cosmo mặt hàng theo các thông tin sau.`,
  `Vận đơn từ miracle đến moon đang được khởi tạo với starlight kiện hàng. Vui lòng kiểm tra các thông tin cần thiết.`,
  `Bạn có starlight kiện hàng cần gửi từ miracle đến moon. Dưới đây là danh sách thông tin cần cung cấp để hoàn tất quá trình book bill.`,
  `Chuyến hàng từ miracle đến moon bao gồm starlight kiện hàng và cosmo mặt hàng. Hãy đảm bảo rằng mọi thông tin đều chính xác trước khi xác nhận.`,
  `Để tiến hành book bill cho lô hàng từ miracle đi moon, hãy cung cấp đủ thông tin cho starlight kiện hàng và cosmo mặt hàng dưới đây.`,
  `Việc gửi starlight kiện hàng từ miracle đến moon sẽ trở nên nhanh chóng hơn nếu chúng ta có đủ các thông tin quan trọng sau đây.`,
  `Lô hàng từ miracle đi moon cần được kiểm tra kỹ lưỡng trước khi vận chuyển. Hãy đảm bảo thông tin về starlight kiện hàng và cosmo mặt hàng đã đầy đủ.`,
  `Vận đơn từ miracle đến moon đang trong giai đoạn xử lý. Để hoàn tất, chúng ta cần xác nhận starlight kiện hàng và cosmo mặt hàng.`,
  `Để chuyến hàng từ miracle đến moon diễn ra thuận lợi, hãy đảm bảo bạn đã cung cấp đầy đủ thông tin về starlight kiện hàng và cosmo mặt hàng.`,
  `Lô hàng từ miracle đi moon bao gồm starlight kiện hàng và cosmo mặt hàng. Hãy kiểm tra lại thông tin trước khi tiến hành vận chuyển.`,
  `Bạn đang chuẩn bị vận chuyển starlight kiện hàng từ miracle đến moon. Hãy kiểm tra lại thông tin để tránh sai sót.`,
  `Lô hàng từ miracle đi moon đang chờ xử lý. Vui lòng cung cấp thông tin chi tiết về starlight kiện hàng và cosmo mặt hàng để tiến hành nhanh chóng.`,
  `Chuyến hàng của bạn từ miracle đến moon cần xác nhận thông tin về starlight kiện hàng và cosmo mặt hàng để đảm bảo quá trình vận chuyển được thông suốt.`,
  `Để đảm bảo lô hàng từ miracle đến moon được gửi đi đúng thời gian, vui lòng kiểm tra và xác nhận thông tin về starlight kiện hàng và cosmo mặt hàng.`,
  `Việc gửi hàng từ miracle đi moon sẽ nhanh chóng hơn nếu chúng ta có đủ thông tin về starlight kiện hàng và cosmo mặt hàng.`,
  `Lô hàng xuất phát từ miracle đi moon đang trong quá trình xác nhận. Hãy cung cấp đầy đủ thông tin về starlight kiện hàng để tiến hành xử lý.`,
  `Hệ thống đang chuẩn bị lô hàng từ miracle đi moon. Vui lòng xác nhận thông tin để đảm bảo tiến trình vận chuyển.`,
  `Vận đơn miracle - moon cần được xác nhận với starlight kiện hàng và cosmo mặt hàng. Hãy kiểm tra để hoàn tất thủ tục.`,
  `Lô hàng từ miracle đi moon cần chuẩn bị đầy đủ thông tin trước khi vận chuyển. Hãy đảm bảo mọi dữ liệu về kiện hàng đã sẵn sàng.`,
  `Để chuyến hàng miracle - moon được thực hiện nhanh chóng, vui lòng cung cấp thông tin về starlight kiện hàng một cách đầy đủ.`,
  `Đơn hàng từ miracle đi moon đang được xử lý. Hãy đảm bảo rằng mọi thông tin đã được cung cấp đầy đủ để tránh trì hoãn.`,
  `Vui lòng xác nhận lô hàng từ miracle đi moon với starlight kiện hàng và cosmo mặt hàng để tiến hành vận chuyển.`,
  `Lô hàng từ miracle đến moon đang trong quá trình hoàn thiện thông tin. Vui lòng kiểm tra để đảm bảo tất cả dữ liệu đều chính xác.`,
  `Bạn đang đặt vận đơn từ miracle đi moon? Hãy kiểm tra thông tin kiện hàng và mặt hàng để đảm bảo không có sai sót.`,
  `Mọi thứ đã sẵn sàng cho chuyến hàng từ miracle đến moon! Hãy kiểm tra lại thông tin để tiến hành vận chuyển ngay.`,
  `Hệ thống đang xử lý vận đơn từ miracle đi moon. Để hoàn tất, vui lòng xác nhận số lượng kiện hàng và các thông tin liên quan.`,
  `Lô hàng miracle - moon đã sẵn sàng. Hãy đảm bảo tất cả thông tin đều chính xác trước khi xuất kho.`,
  `Bạn đang khởi tạo đơn hàng từ miracle đi moon? Hãy kiểm tra lại số lượng kiện hàng trước khi xác nhận.`,
  `Lô hàng miracle - moon đang trong quá trình xác nhận. Hãy kiểm tra thông tin để đảm bảo tất cả dữ liệu đều chính xác.`,
  `Quy trình vận chuyển miracle - moon sẽ nhanh chóng hơn nếu bạn đảm bảo thông tin kiện hàng chính xác ngay từ đầu.`,
  `Hệ thống đã tiếp nhận vận đơn từ miracle đến moon. Vui lòng kiểm tra lại thông tin để hoàn tất quá trình đặt hàng.`,
  `Để hoàn tất việc vận chuyển từ miracle đi moon, vui lòng cung cấp đầy đủ thông tin kiện hàng và mặt hàng.`,
  `Việc vận chuyển từ miracle đến moon đang trong giai đoạn xác nhận. Hãy đảm bảo rằng thông tin kiện hàng đã chính xác.`,
  `Hệ thống đang kiểm tra dữ liệu cho lô hàng từ miracle đi moon. Hãy đảm bảo các thông tin kiện hàng đã đầy đủ.`,
  `Lô hàng từ miracle đi moon cần có đầy đủ dữ liệu kiện hàng trước khi tiến hành vận chuyển.`,
  `Để đảm bảo vận đơn từ miracle đi moon được xử lý nhanh chóng, vui lòng cung cấp đầy đủ thông tin kiện hàng.`,
  `Hãy đảm bảo bạn đã cung cấp chính xác số lượng starlight kiện hàng và cosmo mặt hàng trước khi xác nhận.`,
  `Lô hàng miracle - moon sẽ sớm được vận chuyển. Kiểm tra lại thông tin kiện hàng ngay bây giờ.`,
  `Mọi dữ liệu cho chuyến hàng miracle - moon đã được cập nhật. Vui lòng xác nhận thông tin.`,
  `Bạn có starlight kiện hàng đang chờ xử lý từ miracle đến moon. Hãy kiểm tra thông tin để đảm bảo không có sai sót.`,
  `Để chuyến hàng miracle - moon được gửi đi đúng thời gian, vui lòng xác nhận số lượng starlight kiện hàng.`,
  `Chuyến hàng miracle - moon đang trong quá trình chuẩn bị. Vui lòng kiểm tra dữ liệu kiện hàng trước khi tiến hành.`,
  `Mọi thứ đã sẵn sàng cho vận đơn miracle - moon. Hãy đảm bảo rằng số lượng kiện hàng chính xác.`,
  `Quy trình đặt vận đơn miracle - moon đang được tiến hành. Vui lòng kiểm tra lại thông tin kiện hàng để hoàn tất.`,
  `Hãy kiểm tra lại thông tin kiện hàng cho chuyến hàng miracle - moon để tránh sai sót.`,
  `Lô hàng miracle - moon đang trong quá trình xác nhận. Hãy đảm bảo bạn đã kiểm tra số lượng starlight kiện hàng.`,
  `Để chuẩn bị lô hàng từ miracle đến moon, chúng ta cần thông tin chi tiết về starlight kiện hàng và cosmo mặt hàng.`,
  `Hãy kiểm tra lại số lượng starlight kiện hàng và cosmo mặt hàng trước khi xác nhận đơn hàng từ miracle đến moon.`,
  `Bạn sắp gửi một lô hàng từ miracle đến moon với starlight kiện hàng. Hãy đảm bảo tất cả thông tin đều chính xác.`,
  `Chuyến hàng từ miracle đi moon đang được xử lý. Vui lòng xác nhận lại thông tin về starlight kiện hàng.`,
  `Đơn hàng miracle - moon cần cập nhật thông tin về starlight kiện hàng trước khi tiến hành vận chuyển.`,
  `Việc vận chuyển từ miracle đến moon sẽ suôn sẻ hơn nếu chúng ta đảm bảo dữ liệu về kiện hàng đã đầy đủ.`,
  `Lô hàng miracle - moon đã sẵn sàng để vận chuyển. Hãy kiểm tra lại thông tin trước khi xác nhận.`,
  `Bạn đang đặt vận đơn từ miracle đến moon? Hãy xác nhận số lượng starlight kiện hàng để tiếp tục.`,
  `Lô hàng miracle - moon cần được cập nhật dữ liệu kiện hàng để đảm bảo quá trình vận chuyển diễn ra thuận lợi.`,
  `Chuyến hàng miracle - moon đang chờ xử lý. Kiểm tra và cập nhật số lượng starlight kiện hàng ngay bây giờ.`,
  `Vận đơn từ miracle đến moon cần thông tin chi tiết về kiện hàng trước khi hoàn tất thủ tục.`,
  `Hệ thống đang xử lý lô hàng miracle - moon. Vui lòng xác nhận lại số lượng starlight kiện hàng.`,
  `Mọi thứ đã sẵn sàng cho đơn hàng miracle - moon. Hãy đảm bảo tất cả thông tin đều đầy đủ.`,
  `Lô hàng miracle - moon sẽ được gửi đi sớm. Hãy kiểm tra số lượng starlight kiện hàng trước khi xác nhận.`,
  `Để hoàn tất đơn hàng từ miracle đến moon, vui lòng kiểm tra thông tin kiện hàng thật kỹ.`,
  `Bạn sắp gửi starlight kiện hàng từ miracle đến moon. Hãy đảm bảo rằng mọi dữ liệu đã được nhập chính xác.`,
  `Để quá trình vận chuyển miracle - moon được thực hiện nhanh chóng, hãy kiểm tra lại thông tin kiện hàng.`,
  `Hãy đảm bảo tất cả thông tin về starlight kiện hàng từ miracle đến moon đã được cập nhật trước khi xác nhận.`,
  `Lô hàng miracle - moon đang chờ xử lý. Hãy cập nhật số lượng kiện hàng trước khi tiến hành vận chuyển.`,
  `Chuyến hàng miracle - moon của bạn sẽ sớm khởi hành. Kiểm tra lại thông tin kiện hàng ngay bây giờ.`,
  `Vận đơn miracle - moon đang trong giai đoạn hoàn tất. Xác nhận lại số lượng starlight kiện hàng để tiến hành.`,
  `Mọi thông tin kiện hàng miracle - moon đã được cập nhật. Hãy đảm bảo mọi thứ đều chính xác trước khi gửi.`,
  `Để đảm bảo chuyến hàng miracle - moon diễn ra trơn tru, vui lòng xác nhận lại thông tin kiện hàng.`,
  `Bạn đang đặt vận đơn miracle - moon? Kiểm tra lại số lượng starlight kiện hàng ngay bây giờ.`,
  `Lô hàng miracle - moon của bạn cần được xác nhận số lượng starlight kiện hàng để hoàn tất thủ tục vận chuyển.`,
  `Quy trình vận chuyển miracle - moon cần thông tin đầy đủ về kiện hàng để tiến hành xử lý.`,
  `Bạn đang đặt hàng từ miracle đến moon? Hãy kiểm tra lại thông tin kiện hàng trước khi xác nhận.`,
  `Để quá trình vận chuyển miracle - moon diễn ra nhanh chóng, hãy cập nhật thông tin kiện hàng sớm nhất có thể.`,
  `Mọi dữ liệu về kiện hàng miracle - moon đã được nhập. Hãy kiểm tra lần cuối trước khi xác nhận.`,
  `Hệ thống đang xử lý vận đơn miracle - moon. Hãy đảm bảo rằng tất cả thông tin đều chính xác.`,
  `Bạn có lô hàng từ miracle đến moon đang chờ xử lý. Hãy kiểm tra lại dữ liệu kiện hàng ngay bây giờ.`,
  `Lô hàng miracle - moon đã được cập nhật thông tin. Hãy kiểm tra lại trước khi gửi đi.`,
  `Hệ thống đang xác nhận vận đơn miracle - moon. Hãy kiểm tra lại dữ liệu kiện hàng để tránh sai sót.`,
  `Chuyến hàng miracle - moon sẽ được vận chuyển sớm. Kiểm tra lại thông tin kiện hàng ngay hôm nay.`,
  `Để đảm bảo đơn hàng miracle - moon diễn ra suôn sẻ, hãy xác nhận lại số lượng kiện hàng trước khi tiếp tục.`,
  `Bạn có starlight kiện hàng từ miracle đến moon đang chờ xử lý. Kiểm tra thông tin ngay để hoàn tất quy trình.`,
  `Chuyến hàng miracle - moon của bạn đã sẵn sàng. Hãy xác nhận lại thông tin trước khi vận chuyển.`,
  `Hệ thống đã cập nhật đơn hàng miracle - moon. Hãy kiểm tra lại thông tin kiện hàng ngay bây giờ.`,
  `Lô hàng miracle - moon đang trong giai đoạn hoàn tất. Hãy đảm bảo thông tin kiện hàng đã chính xác.`,
  `Mọi thứ đã sẵn sàng cho vận đơn miracle - moon. Kiểm tra lại thông tin kiện hàng để đảm bảo không có sai sót.`,
  `Để tránh sai sót khi vận chuyển từ miracle đến moon, hãy kiểm tra lại số lượng starlight kiện hàng.`,
  `Quy trình vận chuyển miracle - moon đang được tiến hành. Xác nhận số lượng kiện hàng ngay bây giờ.`,
  `Bạn có vận đơn miracle - moon đang chờ xác nhận. Hãy kiểm tra lại thông tin kiện hàng để tiếp tục.`,
  `Lô hàng miracle - moon sẽ được vận chuyển sớm. Hãy kiểm tra lại số lượng starlight kiện hàng trước khi xác nhận.`,
  `Hệ thống đang chuẩn bị vận đơn miracle - moon. Hãy cập nhật thông tin kiện hàng ngay bây giờ.`,
  `Bạn đang đặt hàng từ miracle đến moon? Kiểm tra thông tin kiện hàng để đảm bảo mọi thứ chính xác.`,
  `Lô hàng miracle - moon đang trong quá trình kiểm tra. Hãy đảm bảo số lượng kiện hàng đã được nhập đầy đủ.`,
  `Bạn đang khởi tạo vận đơn miracle - moon? Hãy xác nhận lại số lượng starlight kiện hàng ngay bây giờ.`,
  `Để tránh lỗi khi vận chuyển từ miracle đến moon, hãy xác nhận lại thông tin kiện hàng trước khi gửi đi.`,
  `Quy trình vận chuyển miracle - moon cần dữ liệu kiện hàng chính xác. Hãy kiểm tra lại trước khi xác nhận.`,
];
