import Masonry from '@mui/lab/Masonry';
import { TextField } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import WordGeneratorEffect from './WordGeneratorEffect';
import { useState, useEffect, useRef } from 'react';
import { HiArrowsExpand, HiOutlineQuestionMarkCircle } from 'react-icons/hi';
import { BsQrCode } from 'react-icons/bs';
import TitleWordEffect from './TitleWordEffect';
import { GoDotFill } from 'react-icons/go';
import { FaStarOfLife } from 'react-icons/fa6';
export default function ErrScript({
  chat,
  handleChildInputChange,
  serviceArray,
  exportReasonArray,
  handleDeleteShipment,
  handleAddSingleShipment,
  handleDeleteSingleShipment,
  handleBookSingleShipment,
  type,
  skydartLogo,
  currentStep,
  isMultiColor,
  handleEffectCompleteLast,
  handleEffectComplete,
  isFontStyle,
  handleShowHelp,
}) {
  const [seeMore, setSeeMore] = useState(false);
  const [weight, setWeight] = useState(0.5);
  const [seenIndexes, setSeenIndexes] = useState({});
  const navigate = useNavigate();
  return (
    <div>
      {currentStep > 2 && chat.errLevel !== 'no-error' && (
        <div
          className={`bg-white border-zinc-300 border-solid border-[1px] w-full overflow-hidden  rounded-[17px]   relative doxScript-box mt-[15px] transition-[95s]`}
        >
          <div className=" bg-white p-2.5 pb-1 px-4 flex items-center border-b-[1px] border-solid border-zinc-200">
            <div className="flex items-center w-full">
              <div className="font-medium text-rose-600 font-mono">
                <TitleWordEffect
                  isSeen={chat.isSeen}
                  text={`Thông tin còn trống, hãy bổ sung nếu quan trọng (*)`}
                  onComplete={() => {
                    handleEffectComplete(chat.id);
                  }}
                />
              </div>
              <HiOutlineQuestionMarkCircle
                size={30}
                className="p-1 rounded-full text-zinc-600 ml-auto hover:opacity-70 active:scale-90 cursor-pointer"
                onClick={() => {
                  handleShowHelp();
                }}
              />
            </div>
          </div>

          {currentStep > 3 && (
            <div className="flex items-start m-2 gap-3 mx-4">
              <div>
                {chat.missingContent?.map((text, index) => {
                  const isLastItem = index === chat.missingContent.length - 1;
                  const isPrevExampleDone = index === 0 || seenIndexes[index - 1] === 'exampleDone';

                  return (
                    <React.Fragment key={index}>
                      {currentStep >= index + 4 && (
                        <div className="py-2 flex items-start">
                          {!text.required && (
                            <GoDotFill size={18} className="flex-shrink-0 mt-[3.5px] retriveShipment1" />
                          )}
                          {text.required && (
                            <FaStarOfLife size={18} className="flex-shrink-0 mt-[3.5px] p-[3.5px] retriveShipment1" />
                          )}
                          <div className="ml-2.5 w-full mr-2.5">
                            <WordGeneratorEffect
                              isSeen={chat.isSeen}
                              isFontStyle={isFontStyle}
                              text={text.value}
                              delay={50}
                              onComplete={() => {
                                setSeenIndexes((prev) => ({ ...prev, [index]: 'textDone' }));
                              }}
                              isColor={true}
                              isMultiColor={false}
                            />
                            {seenIndexes[index] === 'textDone' && (
                              <div className="italic text-zinc-400 tracking-wide">
                                <WordGeneratorEffect
                                  isSeen={chat.isSeen}
                                  isFontStyle={isFontStyle}
                                  text={text?.example}
                                  delay={50}
                                  onComplete={() => {
                                    setSeenIndexes((prev) => ({ ...prev, [index]: 'exampleDone' }));
                                    if (isLastItem) {
                                      handleEffectCompleteLast(chat.id);
                                    } else {
                                      handleEffectComplete(chat.id);
                                    }
                                  }}
                                  isColor={true}
                                  isMultiColor={false}
                                />
                              </div>
                            )}
                            {seenIndexes[index] === 'exampleDone' && (
                              <div className="italic text-zinc-400 tracking-wide">{text?.example}</div>
                            )}
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
          {chat.isSeen && chat.errLevel !== 'no-error' && (
            <div>
              <div className="m-5 mb-2.5 p-2.5 whitespace-pre-line fadebox-stg bg-zinc-100 rounded-[6px]">
                <p className="font-medium tracking-wide">Gửi chứng từ, hồ sơ, giấy tờ (DOX):</p>
                <div className="text-zinc-600">
                  <WordGeneratorEffect
                    isSeen={chat.isSeen}
                    isFontStyle={isFontStyle}
                    text={`DOX, Người nhận: Nguyen Van A,n.a@gmail.com, 0123456789;
              123456 THE W3E ST, ELMONTE, CA, 72038, USA,`}
                    delay={50}
                    isColor={true}
                    isMultiColor={false}
                  />
                </div>
              </div>
              <div className="m-5 mt-2.5 p-2.5 bg-zinc-100 whitespace-pre-line rounded-[6px] fadebox-stg">
                <p className="font-medium tracking-wide">Gửi hàng hoá thông thường (WPX):</p>
                <div className="text-zinc-600">
                  <WordGeneratorEffect
                    isSeen={chat.isSeen}
                    isFontStyle={isFontStyle}
                    text={`D: 25 x R: 14 x C: 22 cm, Nặng: 35 Kg 
                Người nhận: Nguyen Van A,n.a@gmail.com, 0123456789;
                123456 THE W3E ST, ELMONTE, CA, 72038,  USA, 

                hoá đơn: Belt - Thắt lưng (5) [2/pcs/NSX:Deedword,5b Pho Quang/230922/Thành phần: 100% Cotton/ Nhãn hiệu: Gucci/ FDA: ???/ BAG/ use for: lam dep anh oi]`}
                    delay={50}
                    isColor={true}
                    isMultiColor={false}
                  />
                </div>
              </div>
            </div>
          )}
          {!chat.isSeen && (
            <div className="absolute bottom-0 bg-white/60 z-2 flex items-center p-3 justify-center w-full text-black retriveShipment h-[70px]"></div>
          )}
        </div>
      )}
      {currentStep > 17 && chat.errLevel === 'no-error' && (
        <div
          className={`bg-white border-zinc-300 border-solid border-[1px] w-full overflow-hidden  rounded-[17px] ${
            seeMore ? '' : chat.isBooking ? '' : 'max-h-[300px]'
          }   relative doxScript-box cursor-pointer mt-[15px] transition-[95s]`}
          onClick={() => {
            setSeeMore(!seeMore);
          }}
        >
          <div className=" bg-white p-2.5 pb-1 px-4 flex items-center border-b-[1px] border-solid border-zinc-200">
            <div className="flex items-center w-full">
              <div className="font-medium text-rose-600 font-mono">
                <TitleWordEffect
                  isSeen={chat.isSeen}
                  text={`Thông tin còn trống, hãy bổ sung nếu quan trọng (*)`}
                  onComplete={() => {
                    handleEffectComplete(chat.id);
                  }}
                />
              </div>
              <HiOutlineQuestionMarkCircle
                size={30}
                className="p-1 rounded-full text-zinc-600 ml-auto hover:opacity-70 active:scale-90 cursor-pointer"
              />
            </div>
          </div>

          {currentStep > 18 && (
            <div className="flex items-start m-2 gap-3 mx-4">
              <div>
                {chat.missingContent?.map((text, index) => {
                  const isLastItem = index === chat.missingContent.length - 1;
                  const isPrevExampleDone = index === 0 || seenIndexes[index - 1] === 'exampleDone';

                  return (
                    <React.Fragment key={index}>
                      {currentStep - 19 >= index && (
                        <div className="py-2 flex items-start">
                          {!text.required && (
                            <GoDotFill size={18} className="flex-shrink-0 mt-[3.5px] retriveShipment1" />
                          )}
                          {text.required && (
                            <FaStarOfLife size={18} className="flex-shrink-0 mt-[3.5px] p-[3.5px] retriveShipment1" />
                          )}
                          <div className="ml-2.5 w-full mr-2.5">
                            <WordGeneratorEffect
                              isSeen={chat.isSeen}
                              isFontStyle={isFontStyle}
                              text={text.value}
                              delay={50}
                              onComplete={() => {
                                setSeenIndexes((prev) => ({ ...prev, [index]: 'textDone' }));
                              }}
                              isColor={true}
                              isMultiColor={false}
                            />
                            {seenIndexes[index] === 'textDone' && (
                              <div className="italic text-zinc-400 tracking-wide">
                                <WordGeneratorEffect
                                  isSeen={chat.isSeen}
                                  isFontStyle={isFontStyle}
                                  text={text?.example}
                                  delay={50}
                                  onComplete={() => {
                                    setSeenIndexes((prev) => ({ ...prev, [index]: 'exampleDone' }));
                                    if (isLastItem) {
                                      handleEffectCompleteLast(chat.id);
                                    } else {
                                      handleEffectComplete(chat.id);
                                    }
                                  }}
                                  isColor={true}
                                  isMultiColor={false}
                                />
                              </div>
                            )}
                            {seenIndexes[index] === 'exampleDone' && (
                              <div className="italic text-zinc-400 tracking-wide">{text?.example}</div>
                            )}
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
          {!chat.isSeen && (
            <div className="absolute bottom-0 bg-white/60 z-2 flex items-center p-3 justify-center w-full text-black retriveShipment h-[70px]"></div>
          )}
          {chat.isSeen && !seeMore && (
            <div className="absolute bottom-0 bg-white/60 z-2 flex items-center p-3 justify-center w-full cursor-pointer text-black retriveShipment hover:opacity-70 active:opacity-50">
              <p>Xem thêm</p>
            </div>
          )}
        </div>
      )}

      {/* {currentStep > 4 && (
        <div className=" pt-4">
          <WordGeneratorEffect
            isSeen={chat.isSeen}
            isFontStyle={isFontStyle}
            text={chat?.subText[4].value || 'Nếu bạn cần hỗ trợ thêm điều gì, hãy cho tôi biết nhé!'}
            delay={50}
            onComplete={() => {
              handleEffectCompleteLast(chat.id);
            }}
            isColor={true}
            isMultiColor={isMultiColor}
          />
        </div>
      )} */}
    </div>
  );
}
