import { unknownArray } from './unknownArray';

const questionPatterns = {
  shipping: ['gửi hàng', 'ship', 'vận chuyển', 'chuyển phát nhanh'],
  payment: ['thanh toán', 'giá', 'chi phí', 'tiền'],
  customs: ['hải quan', 'thuế', 'thủ tục nhập khẩu', 'tờ khai'],
  complaints: ['khiếu nại', 'phàn nàn', 'dịch vụ kém', 'trả hàng'],
  tracking: ['tra cứu', 'định vị', 'đơn hàng đâu', 'vận đơn'],
  promotions: ['giảm giá', 'khuyến mãi', 'ưu đãi', 'voucher'],
  support: ['hỗ trợ', 'CSKH', 'giúp đỡ', 'trợ giúp'],
  lifestyle: ['cuộc sống', 'tâm sự', 'trò chuyện'],
  smalltalk: ['chào', 'bạn khỏe không', 'hôm nay thế nào', 'tám chuyện'],
  refund: ['hoàn tiền', 'trả hàng', 'hoàn lại tiền'],
  policy: ['chính sách', 'điều khoản', 'quy định', 'bảo hiểm'],
};

const emotionPatterns = {
  positive: ['tuyệt vời', 'xuất sắc', 'đỉnh', 'yêu', 'hài lòng'],
  negative: ['tệ', 'ngu', 'dở', 'rác rưởi', 'không thể chấp nhận'],
  neutral: ['bình thường', 'ổn', 'khá', 'cũng được'],
  angry: ['giận', 'bực', 'điên', 'tức giận'],
  sad: ['buồn', 'chán', 'cô đơn', 'mệt mỏi'],
  sarcastic: ['quá tuyệt luôn', 'dịch vụ VIP à', 'không thể tin nổi'],
  confused: ['không hiểu', 'rối quá', 'giải thích lại'],
  impatient: ['sao lâu vậy', 'quá chậm', 'đợi mãi'],
  excited: ['quá vui', 'không thể chờ được'],
  skeptical: ['thật không', 'không tin được'],
  apologetic: ['xin lỗi', 'rất tiếc', 'mong thông cảm'],
  supportive: ['cố lên', 'bạn làm tốt lắm'],
};

const botReplies = {
  shipping: ['Bạn muốn gửi hàng? Tôi có thể giúp bạn!', 'Bạn cần thông tin về vận chuyển?', 'Gửi hàng đi đâu nhỉ?'],
  payment: [
    'Bạn đang quan tâm đến thanh toán?',
    'Tôi có thể giúp bạn về chi phí và hóa đơn!',
    'Bạn cần hỗ trợ về giá?',
  ],
  tracking: [
    'Bạn muốn tra cứu đơn hàng? Hãy nhập mã vận đơn!',
    'Kiểm tra đơn hàng giúp bạn ngay!',
    'Bạn cần theo dõi đơn hàng nào?',
  ],
  support: [
    'Bạn cần hỗ trợ gì? Tôi sẵn sàng giúp!',
    'Hãy mô tả vấn đề của bạn, tôi sẽ hỗ trợ!',
    'Bạn có thể liên hệ tổng đài nếu cần hỗ trợ thêm!',
  ],
  refund: [
    'Bạn muốn hoàn tiền? Hãy cung cấp thông tin đơn hàng!',
    'Chính sách hoàn tiền như sau...',
    'Tôi có thể giúp bạn xử lý hoàn tiền!',
  ],
  policy: [
    'Bạn muốn biết về chính sách? Đây là thông tin cần thiết!',
    'Chúng tôi có các quy định sau...',
    'Bạn có thắc mắc gì về điều khoản không?',
  ],
  unknown: unknownArray,
  positive: ['Cảm ơn bạn! Tôi rất vui khi nghe điều đó!', 'Thật tuyệt khi bạn hài lòng!', 'Bạn làm tôi vui quá!'],
  negative: [
    'Tôi rất tiếc nếu bạn không hài lòng. Tôi có thể giúp gì không?',
    'Chúng tôi sẽ cải thiện, bạn có góp ý gì không?',
    'Tôi sẽ cố gắng giúp bạn tốt hơn!',
  ],
  angry: [
    'Tôi hiểu bạn đang không hài lòng. Tôi sẽ cố gắng hỗ trợ tốt nhất!',
    'Mong bạn bình tĩnh, tôi sẽ xử lý ngay!',
    'Rất xin lỗi về sự bất tiện này!',
  ],
  sad: [
    'Tôi rất tiếc khi nghe điều đó. Tôi sẵn sàng lắng nghe bạn!',
    'Bạn có muốn chia sẻ thêm không?',
    'Mong bạn sớm vui trở lại!',
  ],
  sarcastic: ['Ồ, thật sự rất tuyệt đúng không?', 'Dịch vụ VIP đây rồi!', 'Cảm ơn phản hồi "chân thành" của bạn!'],
  confused: [
    'Bạn chưa rõ phần nào? Tôi có thể giải thích thêm!',
    'Có vẻ hơi khó hiểu, bạn có thể hỏi lại cụ thể hơn không?',
    'Tôi sẵn sàng giúp bạn hiểu rõ hơn!',
  ],
  impatient: [
    'Tôi hiểu bạn đang chờ lâu. Tôi sẽ kiểm tra ngay!',
    'Xin lỗi vì sự chậm trễ. Tôi đang xử lý!',
    'Cảm ơn vì sự kiên nhẫn của bạn! Tôi sẽ cập nhật ngay.',
  ],
  skeptical: [
    'Bạn nghi ngờ điều này? Tôi có thể cung cấp thêm thông tin!',
    'Tôi sẽ cố gắng làm rõ hơn cho bạn!',
    'Bạn có muốn kiểm chứng thêm thông tin không?',
  ],
  apologetic: [
    'Tôi rất tiếc về điều này. Mong bạn thông cảm!',
    'Chúng tôi xin lỗi vì sự bất tiện này!',
    'Chúng tôi sẽ cải thiện dịch vụ, cảm ơn bạn đã góp ý!',
  ],
  supportive: ['Bạn làm tốt lắm! Cố lên!', 'Mọi chuyện sẽ ổn thôi, bạn không cô đơn đâu!', 'Tôi tin bạn sẽ làm được!'],
};

export { questionPatterns, emotionPatterns, botReplies };
