import { InputAdornment, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { CiLocationOn } from 'react-icons/ci';
import OutsideClickHandler from 'react-outside-click-handler';
import { motion } from 'framer-motion';
import { countries } from '../../../../staff/api/countries';
import AddressGeneratorEffect from './AddressGeneratorEffect';
import WordGeneratorEffect from './WordGeneratorEffect';

export default function PrimaryBox({ type, chat, isFontStyle, isMultiColor, handleSelectShipmentType }) {
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState('Tuyệt vời! Cập nhật thông tin mới thành công');
  const [shipmentType, setShipmentType] = useState('');
  const [toCountry, setToCountry] = useState('');
  const [toCode, setToCode] = useState('');
  const [displayCountry, setDisplayCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [error, setError] = useState(false);
  const [service, setService] = useState('');
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (selectedCountry === '') {
      setToCode('');
      setToCountry('');
    }
  }, [selectedCountry]);

  const handleSubmitCountryAndType = (id) => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      setLoadingContent('Đang chỉnh sửa');
      setTimeout(() => {
        setLoading(false);
        handleSelectShipmentType(selectedCountry, shipmentType, id);
        setLoadingContent('Tuyệt vời! Cập nhật thông tin mới thành công');
      }, 2000);
    }, 2000);
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitCountryAndType(chat.id);
      }}
    >
      {/* {chat.shipment.toCountry === '' && (
        
      )} */}
      {chat.shipment.toCountry === '' && (
        <div className="mb-1">
          <WordGeneratorEffect
            isSeen={chat.isSeen}
            isFontStyle={isFontStyle}
            text={'Bạn muốn gửi hàng đến quốc gia/ nước nào ?'}
            delay={50}
            isColor={true}
            isMultiColor={isMultiColor}
          />
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayCountry(false);
            }}
          >
            <div className="flex relative my-2">
              <div className="flex items-center">
                <TextField
                  label="Tên nước / quốc gia điểm đến"
                  placeholder="Nhập và tìm kiếm tên nước"
                  variant="outlined"
                  size="small"
                  error={error}
                  required
                  value={toCountry}
                  onChange={(e) => setToCountry(e.target.value)}
                  autoComplete="off"
                  onBlur={(e) => {
                    const findMatchCountry = countries.filter((s) => {
                      return s.label.toLowerCase() === e.target.value.toLowerCase();
                    });
                    const findMatchCode = countries.filter((s) => {
                      return s.code.toLowerCase() === e.target.value.toLowerCase();
                    });
                    if (findMatchCountry.length !== 0) {
                      setToCode(findMatchCountry[0].code);
                      setToCountry(findMatchCountry[0].label);
                      setSelectedCountry(findMatchCountry[0].label);
                    } else if (findMatchCode.length !== 0) {
                      setToCode(findMatchCode[0].code);
                      setToCountry(findMatchCode[0].label);
                      setSelectedCountry(findMatchCode[0].label);
                    } else {
                      setError(true);
                      setSelectedCountry('');
                    }
                  }}
                  onClick={() => {
                    setDisplayCountry(true);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {toCode && (
                          <img
                            src={`https://flagsapi.com/${toCode}/shiny/64.png`}
                            loading="lazy"
                            className="w-[25px] h-[25px] object-cover"
                          />
                        )}
                        {!toCode && <CiLocationOn size={23} />}
                      </InputAdornment>
                    ),
                  }}
                  style={{ background: 'white', width: 320, flexShrink: 0 }}
                />
                <p className="text-[14px] italic text-zinc-500 ml-3">
                  // Ví dụ: United States, US, Canada, CA, Áutralia,AU, Vietnam, VN
                </p>
              </div>
              {displayCountry && (
                <div className="absolute overflow-auto bg-white z-2 w-[320px] max-h-[200px] mt-[41px] p-1  rounded-bl-[4px] rounded-br-[4px] shadow-[rgba(0,0,0,0.05)_0px_0px_0px_1px]">
                  {countries
                    .filter((s) => {
                      if (s.label.toLowerCase().startsWith(toCountry.toLowerCase())) {
                        return s;
                      }
                    })
                    .map((c) => (
                      <div
                        className="p-1 px-[9px] rounded-[4px] font-medium text-slate-600 hover:bg-zinc-100 cursor-pointer transition-[98s]  active:bg-zinc-200 select-none"
                        onClick={() => {
                          setToCountry(c.label);
                          setToCode(c.code);
                          setSelectedCountry(c.label);
                          setDisplayCountry(false);
                        }}
                      >
                        <p>
                          {c.label} ({c.code})
                        </p>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      )}
      {type === '' && (
        <div className="mb-1">
          <WordGeneratorEffect
            isSeen={chat.isSeen}
            isFontStyle={isFontStyle}
            text={
              'Bạn có thể cho tôi biết bạn đang muốn vận chuyển loại hàng hoá gì được không ? Ví dụ: (WPX hoặc DOX)'
            }
            delay={50}
            isColor={true}
            isMultiColor={isMultiColor}
          />
          <select
            value={shipmentType}
            required
            onChange={(e) => {
              setShipmentType(e.target.value);
            }}
            className="my-2.5 p-2 bg-zinc-100 rounded-[6px] hover:bg-zinc-200 active:bg-zinc-300 cursor-pointer outline-none px-4 transition-[95s]"
          >
            <option value="">Loại vận chuyển</option>
            <option value="WPX">WPX (Hàng hoá)</option>
            <option value="DOX">DOX (Chứng từ)</option>
          </select>
        </div>
      )}
      <div className="my-1.5">
        <AddressGeneratorEffect
          isSeen={chat.isSeen}
          isFontStyle={isFontStyle}
          text={'Địa chỉ giao nhận:' + ' ' + chat.shipment.fullAddress}
          delay={50}
          isColor={true}
          isMultiColor={isMultiColor}
        />
      </div>
      <div className="flex items-center my-2 font-medium tracking-wide tezt-zinc-500 hover:opacity-70 active:opacity-50 hover:underline transition-[98s] w-fit">
        <button type="submit" className="text-lg cursor-pointer select-none">
          <p>Tiếp tục tạo vận đơn</p>
        </button>
      </div>
      {loading && (
        <motion.span
          className="text-gray-500 mt-1.5"
          animate={{ opacity: [1, 0.4, 1] }}
          transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut' }}
        >
          {loadingContent}
        </motion.span>
      )}
    </form>
  );
}
